import React from "react";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { editorTabChange, handleSaveDraft, handleDirectPublish, handleEditoverlayClick, tabsElement } from "../template.helperFunctions";
import { Page, Container, Row, Column } from "./style";

import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  saveDraftApi,
  directPublishApi,
  setAnalysisHeadingEditorState,
  setAnalysisHeadingEditorStateForDraft,
  setAnalysisHeadingEditorStateForPublished,
} from "../../reducersAndActions/Actions";

import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import Graph from "../../Graphs"
import Analysis from "../../components/Analysis";
import Subheading from "../../components/Subheading";

var Base64 = require("js-base64").Base64;

class Template1111 extends React.Component {
  state = {
    graphFooterText: "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : {},
    tabPosition: "top",
    editor_active_tab: "analysis",
    reseller: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if (nextProps.data && nextProps.data.meta) {
      if (nextProps.data.meta.sample && nextProps.data.meta.reseller) {
        this.setState({ reseller: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title !== this.props.data.title) {
      this.setState({
        data: this.props.data,
      });

      if (this.props.data && this.props.data.meta) {
        if (this.props.data.meta.sample && this.props.data.meta.reseller) {
          this.setState({ reseller: true });
        }
      }
    }

    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        this.props.getDrafts(this.props.parent_id, this.props.template_type);
      }
    }

    if (this.props.published_drawer_visible !== prevProps.published_drawer_visible) {
      if (this.props.published_drawer_visible) {
        this.props.getPublishedVersions(this.props.parent_id);
      }
    }

    if (this.props.edit_mode !== prevProps.edit_mode) {
      this.setState({
        editor_active_tab: "analysis",
      });
    }
  }

  render() {
    const setState = this.setState.bind(this);
    const func = { editorTabChange, handleDirectPublish, handleSaveDraft, handleEditoverlayClick, setState };
    const { idx, data, template, template_title, slideNo } = this.props;
    const pageId = data.section_id ? Base64.encode(data.section_id) : undefined;
    let slideNoElementId = undefined;
    let templateData;
    let template_name;

    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    if (["indicator1", "indicator2", "indicator3", "global_data_pack"].includes(template)) {
      templateData =
        data &&
        data.data &&
        data.data[idx].multitemplate_names.map((elem) => elem)[0];
      template_name = templateData.template_name;
    } else {
      templateData = data && data.data && data.data.multitemplate_names[idx];
      template_name = templateData.template_name;
    }

    return (
      <Page className="page dynamic_data_packs" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={data.type} />
        ) : null}

        <Container formInterface={this.props?.formInterface}>
          <Heading heading={template_title} />
          <Row>
            {templateData.config_data.slice(0, 2).map((item) => {
              if (item.type === "graph") {
                return (
                  <Column>
                    <Graph
                      legendsPositionDynamic={true}
                      rotateValues={0}
                      chartHeadingGap={10}
                      yHeadingMargin={25}
                      headers={item.data.heading}
                      data={item.data.data}
                      width={
                        !this.props.fromInterface ||
                        this.props.fromInterface === undefined
                          ? "566"
                          : "566"
                      }
                      height={266}
                      totalData={item.data}
                      graphType={item.data.graphType}
                      dynamiclegends={item.data.legends}
                      template_name={template_name}
                      slideNo
                    />
                  </Column>
                );
              } else if (item.type === "analysis") {
                return (
                  <Column>
                    {!this.props.edit_mode ? (
                      item.data.meta.heading ? (
                        <Subheading subheading={item.data.meta.heading} />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {this.props.edit_mode ? (
                      this.props.no_drafts_found &&
                      this.props.ACTIVE_TAB === "drafts" ? (
                        <div
                          style={{ border: "1px solid grey", padding: "20px" }}
                          className="no-drafts-overlay"
                        >
                          <p>There is nothing in draft right now</p>
                          <Button
                            onClick={() => handleEditoverlayClick(this.props)}
                          >
                            Edit
                          </Button>
                        </div>
                      ) : this.props.initial_draft_versions_loading ? (
                        <Spin
                          style={{ display: "flex", justifyContent: "center" }}
                        />
                      ) : (
                        tabsElement(
                          item,
                          data,
                          this.state,
                          this.props,
                          func,
                          900
                        )
                      )
                    ) : (
                      <Analysis
                        trends={item.data}
                        edit_mode={this.props.edit_mode}
                        section_id={data.section_id}
                      />
                    )}
                  </Column>
                );
              }
            })}
          </Row>
          <Row>
            {templateData.config_data.slice(-2).map((item, index) => {
              if (item.type === "graph") {
                return (
                  <Column>
                    <Graph
                      legendsPositionDynamic={true}
                      rotateValues={0}
                      chartHeadingGap={10}
                      yHeadingMargin={25}
                      headers={item.data.heading}
                      data={item.data.data}
                      width={
                        !this.props.fromInterface ||
                        this.props.fromInterface === undefined
                          ? "566"
                          : "556"
                      }
                      height={266}
                      totalData={item.data}
                      graphType={item.data.graphType}
                      dynamiclegends={item.data.legends}
                      template_name={template_name}
                      slideNo
                    />
                  </Column>
                );
              } else if (item.type === "analysis") {
                return (
                  <Column>
                    {!this.props.edit_mode ? (
                      item.data.meta.heading ? (
                        <Subheading subheading={item.data.meta.heading} />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {this.props.edit_mode ? (
                      this.props.no_drafts_found &&
                      this.props.ACTIVE_TAB === "drafts" ? (
                        <div
                          style={{ border: "1px solid grey", padding: "20px" }}
                          className="no-drafts-overlay"
                        >
                          <p>There is nothing in draft right now</p>
                          <Button
                            onClick={() => handleEditoverlayClick(this.props)}
                          >
                            Edit
                          </Button>
                        </div>
                      ) : this.props.initial_draft_versions_loading ? (
                        <Spin
                          style={{ display: "flex", justifyContent: "center" }}
                        />
                      ) : (
                        this.tabsElement(item, data)
                      )
                    ) : (
                      <Analysis
                        trends={item.data}
                        edit_mode={this.props.edit_mode}
                        section_id={data.section_id}
                        index={index}
                      />
                    )}
                  </Column>
                );
              }
            })}
          </Row>
        </Container>

        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={data.slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
            fromInterface={this.props.fromInterface}
          />
        ) : null}
      </Page>
    );
  }
}

function mapStateToPros(state) {
  return {
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error,
    template_type: state.template_type,
    save_draft_loader: state.save_draft_loader,
    direct_publish_loader: state.direct_publish_loader,
    analysis_editor_state: state.analysis_editor_state,
    sources_editor_state: state.sources_editor_state,
    analysis_heading_editor_state: state.analysis_heading_editor_state,
    analysis_heading_editor_state_for_published: state.analysis_heading_editor_state_for_published,
    analysis_heading_editor_state_for_draft: state.analysis_heading_editor_state_for_draft,
    analysis_char_count: state.analysis_char_count,
    USER_DATA: state.USER_DATA,
  };
}
const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  saveDraftApi,
  directPublishApi,
  setAnalysisHeadingEditorState,
  setAnalysisHeadingEditorStateForDraft,
  setAnalysisHeadingEditorStateForPublished,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Template1111))
);
